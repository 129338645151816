.stories-overlay {
    backdrop-filter: blur(23px);
}

.stories-modal {
    height:calc(100vh - 10rem);
}


@media screen and (min-width: 768px) {
    .stories-modal {
        height:calc(100vh - 6rem);
    }
}
