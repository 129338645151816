.youtube_video {
    /* overflow:hidden;
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0; */
}
.youtube_video iframe {
    width:100%;
    aspect-ratio: 16 / 9;
    height: auto;
}
