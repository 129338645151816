#category-description{
    @apply text-gray-500;
}

#category-description h2{
    @apply text-2xl text-center text-xl font-bold text-gray-900 mb-6;
}

#category-description h3{
    @apply text-xl font-medium text-gray-500 mb-2;
}

#category-description p{
    @apply text-lg text-gray-500 mb-4;
}

#category-description ul{
    @apply list-disc ml-8;
}

#category-description li{
    @apply text-lg text-gray-500 mb-2;
}