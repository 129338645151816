@font-face {
    font-family: 'Nexa';
    src: url("../fonts/Nexa/Nexa Regular.otf");
    font-weight: normal;
}

/*
@font-face {
    font-family: 'Nexa';
    src: url("../fonts/Nexa/Nexa Bold.otf");
    font-weight: normal;
}
*/

h1, h2, h3, h4, h5, h6 {
    @apply font-header;
}
