.active-underline {
    @apply opacity-0;
}

.active .active-underline {
    @apply opacity-100;
}

.hide-items {
    @apply hidden;
}
