button,
[type='button'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

[type='checkbox']:checked + svg {
    display: block;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='range'] {
    box-sizing: border-box;
    margin: 0;
    padding: 0 2px;
    /* Add some L/R padding to ensure box shadow of handle is shown */
    overflow: hidden;
    border: 0;
    border-radius: 1px;
    outline: none;
    background: linear-gradient(#374345, #374345) no-repeat center;
    /* Use a linear gradient to generate only the 2px height background */
    background-size: 100% 4px;
    pointer-events: none;
}

input[type='range']:active,
input[type='range']:focus {
    outline: none;
}

input[type='range']::-webkit-slider-thumb {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 1.25rem;
    background-color: #435154;
    position: relative;
    margin: 5px 0;
    /* Add some margin to ensure box shadow is shown */
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
}

input[type='range']::-moz-range-thumb {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 1.25rem;
    background-color: #435154;
    position: relative;
    margin: 5px 0;
    /* Add some margin to ensure box shadow is shown */
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
}

input[type='range']::-ms-thumb {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 1.25rem;
    background-color: #435154;
    position: relative;
    margin: 5px 0;
    /* Add some margin to ensure box shadow is shown */
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
}

input[type='range']::-webkit-slider-thumb:hover {
    background-color: #f9a800;
}

input[type='range']::-moz-range-thumb:hover {
    background-color: #f9a800;
}

input[type='range']::-ms-thumb:hover {
    background-color: #f9a800;
}

input[type='range']:nth-child(1)::-webkit-slider-thumb::before {
    background-color: #f9a800;
}

input[type='range']:nth-child(1)::-moz-range-thumb::before {
    background-color: #f9a800;
}

input[type='range']:nth-child(1)::-ms-thumb::before {
    background-color: #f9a800;
}

input[type='range']:nth-child(2) {
    background: none;
}

input[type='range']:nth-child(2)::-webkit-slider-thumb::before {
    background-color: #435154;
}

.pagination {
    display: flex;
}

.pagination .page-item {
    padding: 3px;
    background-color: #435154;
}

input[type='range']:nth-child(2)::-moz-range-thumb::before {
    background-color: #435154;
}

input[type='range']:nth-child(2)::-ms-thumb::before {
    background-color: #435154;
}

.selection .select2-selection.select2-selection--single {
    @apply w-full h-10 rounded-sm bg-gray-90;
}

.delivery .select2-container--default .select2-selection--single {
    @apply border-none rounded-sm bg-gray-90;
}

.delivery
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    @apply top-1/2 transform -translate-y-1/2;
}
.delivery
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    @apply text-xs font-medium;
    line-height: 2.5rem;
}

.select2-search.select2-search--dropdown .select2-search__field {
    @apply text-xs font-medium;
}

.select2-results__options .select2-results__option {
    @apply text-xs font-medium;
}

.select2-container.select2-container--open .select2-dropdown {
    @apply border-none;
}
