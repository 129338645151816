.cart {
    @apply transform scale-0;
}

.cart.show,
.cart.visible {
    @apply scale-100;
}

.mobile-cart {
    @apply transform transition origin-left ease-in duration-300 -translate-x-full;
}

.mobile-cart.active {
    @apply translate-x-0;
}

.mobile-search-container,
.mobile-search-results {
    @apply transition origin-center ease-in duration-300 transform scale-0;
}

.mobile-search-container.show,
.mobile-search-results.show {
    @apply scale-100;
}
.cart-moded__box {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, .8);
    z-index: 999;
}
.cart-model__element {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    width: 1090px;
}
.cart-model-close {
    position: absolute;
    top: 10px;
    right: 31px;
    width: 23px;
    height: 23px;
    cursor: pointer;
}
.cart-model-close:before {
	content: '';
	background-color: #fff;
	position: absolute;
	height: 1px;
	width: 20px;
	top: 11px;
	left: -4px;
	transform: rotate(-45deg);
}

.cart-model-close:after {
	content: '';
	background-color: #fff;
	position: absolute;
	height: 1px;
	width: 20px;
	top: 11px;
	transform: rotate(45deg);
	left: -4px;
}
@media(min-with:1024px) {
    .cart-moded__box {
        display: none;
    }
}
